import React from "react";
import classNames from "classnames";
import "./table.scss";


export const Table = props => {

    const classnames = classNames(`m-table`, {
        "m-table--info": props.info,
        "m-table--first-col-strong": props.firstColStrong,
    });

    const dataHead = props.dataHead;
    const dataBody = props.dataBody;

    return (
        <table className={props.className ? props.className + ` ` + classnames : classnames}>
            {dataHead && (
                <thead>
                    {dataHead.map((value, key) => (
                        <tr>
                            {value.map((v, k) => (
                                <th>{v}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
            )}
            <tbody>
                {dataBody.map((value, key) => (
                    <tr>
                        {value.map((v, k) => (
                            <td>{v}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Table;